import { identityStore } from '~/stores/identityStore'

export default defineNuxtRouteMiddleware(async () => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local')
    console.log('authenticated.ts')

  const app = useNuxtApp()
  const pinia = app.$pinia
  const iStore = identityStore(pinia)

  if (iStore.loggedIn) {
    if (isDev)
      console.log('[unauthenticated] redirecting to /')
    return navigateTo('/')
  }
})
